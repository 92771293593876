import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d9f5bd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["basic", {
      primary: _ctx.primary,
      'primary-reverted': _ctx.primaryReverted,
      'primary-low': _ctx.primaryLow,
      'primary-unfilled': _ctx.primaryUnfilled,
      'multi-select': _ctx.multiSelect,
      decline: _ctx.decline,
      'decline-void': _ctx.declineVoid,
      dark: _ctx.dark,
      danger: _ctx.danger,
      'danger-fill': _ctx.dangerFill,
      reset: _ctx.reset,
      disabled: _ctx.disabled,
    }]),
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}