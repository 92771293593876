
import BaseButton from "../buttons/BaseButton.vue";
import { defineComponent, useSlots } from "vue";
export default defineComponent({
  name: "BaseCard",
  emits: ["accepted", "declined"],
  components: { BaseButton },
  props: {
    accept: {
      type: Boolean,
      required: false,
    },
    acceptDecline: {
      type: Boolean,
      required: false,
    },
    acceptTitle: {
      type: String,
      required: false,
    },
    declineTitle: {
      type: String,
      required: false,
    },
    buttonsAlign: {
      type: String,
      required: false,
      default: "center",
      validator(value: string): boolean {
        return ["left", "center", "right"].includes(value);
      },
    },
    titleAlign: {
      type: String,
      required: false,
      default: "center",
      validator(value: string): boolean {
        return ["left", "center", "right"].includes(value);
      },
    },
    padding: {
      type: String,
      required: false,
      default: "normal",
      validator(value: string): boolean {
        return ["mini", "small", "normal"].includes(value);
      },
    },
    isButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const slots = useSlots();

    function onAccept(): void {
      context.emit("accepted");
    }

    function onDecline(): void {
      context.emit("declined");
    }

    return {
      onAccept,
      onDecline,
      slots,
    };
  },
});
