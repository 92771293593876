
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    primary: {
      type: Boolean,
      required: false,
    },
    primaryReverted: {
      type: Boolean,
      required: false,
    },
    primaryLow: {
      type: Boolean,
      required: false,
    },
    primaryUnfilled: {
      type: Boolean,
      required: false,
    },
    multiSelect: {
      type: Boolean,
      required: false,
    },
    decline: {
      type: Boolean,
      required: false,
    },
    declineVoid: {
      type: Boolean,
      required: false,
    },
    dark: {
      type: Boolean,
      required: false,
    },
    danger: {
      type: Boolean,
      required: false,
    },
    dangerFill: {
      type: Boolean,
      required: false,
    },
    reset: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
});
