import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.iconSrc)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "icon",
        style: _normalizeStyle([
      { 'mask-image': `url(/${_ctx.iconSrc})` },
      { '-webkit-mask-image': `url(/${_ctx.iconSrc})` },
      _ctx.iconColor
        ? { 'background-color': `var(--${_ctx.iconColor})` }
        : { 'background-color': 'var(--additional-high)' },
    ])
      }, null, 4))
    : _createCommentVNode("", true)
}