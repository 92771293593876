import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52188c6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 1,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(["main", `${_ctx.padding}`]),
      style: _normalizeStyle([
        !_ctx.slots.actions && !_ctx.accept && !_ctx.acceptDecline
          ? { 'border-radius': '10px' }
          : { 'border-radius': '10px 10px 0 0' },
      ])
    }, [
      (_ctx.slots.title)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["title", `title-align-${_ctx.titleAlign}`])
          }, [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.slots.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            _renderSlot(_ctx.$slots, "description", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "content",
        style: _normalizeStyle([!_ctx.slots.title && !_ctx.slots.description ? { 'margin-top': '0' } : {}])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ], 6),
    (_ctx.slots.actions || _ctx.accept || _ctx.acceptDecline)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass(["actions", `actions-align-${_ctx.buttonsAlign}`])
        }, [
          (_ctx.accept || _ctx.acceptDecline)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.accept || _ctx.acceptDecline)
                  ? (_openBlock(), _createBlock(_component_base_button, {
                      key: 0,
                      primary: "",
                      onClick: _ctx.onAccept,
                      disabled: _ctx.isButtonDisabled
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.acceptTitle), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.acceptDecline)
                  ? (_openBlock(), _createBlock(_component_base_button, {
                      key: 1,
                      decline: "",
                      onClick: _ctx.onDecline
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.declineTitle), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}