
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseIcon",
  props: {
    iconSrc: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
    },
  },
});
